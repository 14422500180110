import React from 'react';
import styled from 'styled-components';
import { createRoot } from 'react-dom/client';

const Error = styled.div`
  font-size: 22px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
`;

const App = () => <Error>Something went wrong. Please try refreshing the page.</Error>;

const wrapperId = '__loading-error-root__';
let root;

const render = props => {
  const { container } = props;
  const wrapper: HTMLDivElement =
    container.querySelector(`#${wrapperId}`) || document.createElement('div');
  wrapper.id = wrapperId;
  container.appendChild(wrapper);

  root = createRoot(wrapper);
  root.render(<App />);
};

export async function bootstrap() {
  // do nothing
}

export async function mount(props) {
  render(props);
}

export async function unmount() {
  if (root) {
    root.unmount();
  }
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}
